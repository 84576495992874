.report-style-class {
  height: 100vh;
  overflow: hidden;
  /* margin: 1% auto; */
  width: 100%;
}

iframe {
  border: none;
}
